// Import necessary components from Material-UI
import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

const InfoCardsSection = () => (
  <Box sx={{ padding: "40px", backgroundColor: "#f5f5f5" }} id="info-cards">
    <Grid
      container
      spacing={4}
      justifyContent="center"
      sx={{ marginTop: "-130px !important" }}
    >
      {/* Card 1: Online Appointment */}
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MedicalServicesIcon fontSize="large" sx={{ color: "#2a5c82" }} />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              24 Hours Service
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Online Appointment
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Get all-time support for emergencies. We have introduced the
              principle of family medicine.
            </Typography>
          </CardContent>
          <Button
            variant="contained"
            sx={{ m: 2, backgroundColor: "#2a5c82", color: "#fff" }}
          >
            Make Appointment
          </Button>
        </Card>
      </Grid>

      {/* Card 2: Working Hours */}
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AccessTimeIcon fontSize="large" sx={{ color: "#2a5c82" }} />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Timing Schedule
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Working Hours
            </Typography>
            <Box
              component="ul"
              sx={{
                listStyleType: "none",
                padding: 0,
                margin: 0,
              }}
            >
              <Box
                component="li"
                className="d-flex justify-content-between"
                sx={{ mb: 1 }}
              >
                Sun - Wed <span>8:00 - 17:00</span>
              </Box>
              <Box
                component="li"
                className="d-flex justify-content-between"
                sx={{ mb: 1 }}
              >
                Thu - Fri <span>9:00 - 17:00</span>
              </Box>
              <Box
                component="li"
                className="d-flex justify-content-between"
                sx={{ mb: 1 }}
              >
                Sat - Sun <span>10:00 - 17:00</span>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Card 3: Emergency Cases */}
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            textAlign: "center",
            padding: "20px",
            borderRadius: "16px",
            boxShadow: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HeadsetMicIcon fontSize="large" sx={{ color: "#2a5c82" }} />
          </Box>
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
              Emergency Cases
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              1-800-700-6200
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Get all-time support for emergency. We have introduced the
              principle of family medicine. Connect with us for any urgency.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Box>
);

export default InfoCardsSection;
